import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

const HvacTemplate = ({ data }) => {
  const pg = data.sanityHvacPg

  return (
    <DefaultLayout {...pg.seo}>
      {pg.blocks?.map(block => (
        <Block {...block} key={block?._key} />
      ))}
    </DefaultLayout>
  )
}

export default HvacTemplate

export const query = graphql`query HvacTemplate($id: String) {
  sanityHvacPg(id: {eq: $id}) {
    blocks {
      ...areasServed
      ...featuresWithIcons
      ...heroBlock
      ...heroSimple
      ...imageAndTextSections
      ...industriesServed
      ...testimonial
      ...textWithBg
    }
    seo {
      title
      description
      slug { current }
    }
  }
}`
